<template>
    <div class="sm:grid sm:grid-cols-4">
        <div class="col-start-2 col-span-2">
            <font-awesome-icon
                icon="fa-solid fa-lock"
                class="bg-primary text-white w-12 h-12 mx-auto flex rounded-full p-8 mt-4"
            />
            <div class="mt-4 font-bold text-lg text-center">Change PIN TripwePay</div>
            <ValidationObserver v-slot="{ invalid }" class="block mt-8">
                <ValidationProvider name="current PIN" :rules="{ required: true, regex: /^[0-9]+$/ }">
                    <div class="input-field-label">Current PIN</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
                        <input
                            v-model="form.currentPin"
                            :type="showPin.current ? 'number' : 'password'"
                            placeholder="Current PIN"
                            class="input-field"
                            autocomplete="new-password"
                            @keypress="isNumber($event)"
                            maxlength = "6"
                            @input="resetValidate"
                        />
                        <div
                            v-on:click="showPin.current = !showPin.current"
                            class="cursor-pointer text-primary flex items-center"
                        >
                            <font-awesome-icon v-if="showPin.current" icon="fa-solid fa-eye" />
                            <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                        </div>
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="form.validate.current">*Current PIN is required</small>
                </ValidationProvider>
                <ValidationProvider
                    class="block mt-6"
                    name="new PIN"
                    :rules="{ required: true, regex: /^[0-9]+$/, confirmed: { target: 'confirmation' } }"
                >
                    <div class="input-field-label">New PIN</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
                        <input
                            v-model="form.newPin"
                            :type="showPin.new ? 'number' : 'password'"
                            placeholder="New PIN"
                            class="input-field"
                            autocomplete="new-password"
                            @keypress="isNumber($event)"
                            maxlength = "6"
                            @input="resetValidate"
                        />
                        <div
                            v-on:click="showPin.new = !showPin.new"
                            class="cursor-pointer text-primary flex items-center"
                        >
                            <font-awesome-icon v-if="showPin.new" icon="fa-solid fa-eye" />
                            <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                        </div>
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="form.validate.new">*New PIN is required</small>
                </ValidationProvider>
                <ValidationProvider
                    class="block mt-6"
                    name="PIN confirmation"
                    :rules="{ required: true, regex: /^[0-9]+$/ }"
                    vid="confirmation"
                >
                    <div class="input-field-label">Confirm New PIN</div>
                    <div class="flex">
                        <font-awesome-icon class="input-field-icon" icon="fa-solid fa-lock" />
                        <input
                            v-model="form.confirmationPin"
                            :type="showPin.confirmation ? 'number' : 'password'"
                            placeholder="Confirm New PIN"
                            class="input-field"
                            autocomplete="new-password"
                            @keypress="isNumber($event)"
                            maxlength = "6"
                            @input="resetValidate"
                        />
                        <div
                            v-on:click="showPin.confirmation = !showPin.confirmation"
                            class="cursor-pointer text-primary flex items-center"
                        >
                            <font-awesome-icon v-if="showPin.confirmation" icon="fa-solid fa-eye" />
                            <font-awesome-icon v-else icon="fa-solid fa-eye-slash" />
                        </div>
                    </div>
                    <small class="text-red-1 text-xs italic" v-if="form.validate.confirmation">*Confirmation PIN is required</small>
                    <small class="text-red-1 text-xs italic" v-if="form.validate.conf_new">*Confirmation PIN doesn't match with New PIN</small>
                </ValidationProvider>
                <div class="flex justify-between mt-1 text-sm font-bold">
                    <span>NB: PIN only numeric</span>
                </div>
                <div class="flex justify-between mt-10">
                    <button @click="resetForm" class="button-cancel">Cancel</button>
                    <button @click="requestOtp" class="button-submit">Update</button>
                </div>
            </ValidationObserver>
        </div>
        <modal-otp ref="modalOTPRef"></modal-otp>
    </div>
</template>

<script>
    export default {
        name: 'AccountPin',
        components: {
            ModalOtp: () => import('@/components/layout/ModalOtp.vue'),
        },
        data() {
            return {
                form: {
                    currentPin: '',
                    newPin: '',
                    confirmationPin: '',
                    validate: {
                        current: false,
                        new: false,
                        confirmation: false,
                        conf_new: false
                    },
                },
                showPin: {
                    current: false,
                    new: false,
                    confirmation: false
                },
                otp: '',
                otpTimer: 0
            };
        },

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Account Setting', routeName: 'AccountProfile' },
                { text: 'PIN TripwePay', routeName: 'AccountPin' }
            ]);
        },
        computed: {
            isInvalidOtp() {
                return this.otp.toString().length != 6;
            },
            otpTimerMinutes() {
                const val = Math.floor(this.otpTimer / 60);
                return val < 10 ? `0${val}` : val;
            },
            otpTimerSeconds() {
                const val = this.otpTimer - this.otpTimerMinutes * 60;
                return val < 10 ? `0${val}` : val;
            },
            sendOtpInCooldown() {
                return this.otpTimer > 0;
            }
        },

        methods: {
            cekForm(){
            },
            resetValidate(){
                this.form.validate.current = false
                this.form.validate.new = false
                this.form.validate.confirmation = false
                this.form.validate.conf_new = false
            },
            isNumber(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            resetForm() {
                this.form = {
                    currentPin: null,
                    newPin: null,
                    confirmationPin: null
                };
            },
            async requestOtp() {
                if(this.form.currentPin == '' || this.form.currentPin == null){
                    this.form.validate.current = true
                    return false
                }
                if(this.form.newPin == '' || this.form.newPin == null){
                    this.form.validate.new = true
                    return false
                }
                if(this.form.confirmationPin == '' || this.form.confirmationPin == null){
                    this.form.validate.confirmation = true
                    return false
                }
                if(this.form.confirmationPin !=  this.form.newPin){
                    this.form.validate.conf_new = true
                    return false
                }
                
                this.$store.commit('setOverlayLoading', true);

                // const checkData = new FormData();
                // checkData.append('txtTelepon', this.formattedPhone);
                var diff = 0;
                let otpResponse;
                try {
                    otpResponse = await this.$http.post(`${this.$apiTripweWebV2}/users/update-pin/validate`, 
                        {
                            'old_pin': this.form.currentPin,
                            'new_pin': this.form.newPin,
                            'confirm_new_pin': this.form.confirmationPin
                        });
                        
                    if (otpResponse.data.status == 200 || otpResponse.data.status == 403) {
                        var curTime = this.$moment();
                        var nextReq = this.$moment(otpResponse.data.data.next_request_at);
                        diff = nextReq.diff(curTime, 'seconds');
                        if(otpResponse.data.status == 403){
                            this.$toasted.global.error(otpResponse.data.message);
                        }else{
                            this.$toasted.global.success(otpResponse.data.message);
                        }
                    }else{
                        throw otpResponse.data.message;
                    }
                } catch (error) {
                    this.$toasted.global.error(error);
                } finally {
                    this.$store.commit('setOverlayLoading', false);
                }
                if(otpResponse?.data?.status == 200 || otpResponse?.data?.status == 403){
                    const otp = await this.$refs.modalOTPRef.open({ button: "Submit", scope: "UPDATE_PIN", otpTimer: diff });
                    if(otp != null){
                        this.otp = otp;
                        this.updatePin();
                    }
                }
            },
            
            async resendOtp() {
                if (this.sendOtpInCooldown) {
                    this.$toasted.global.error(
                    "Please wait a moment before sending new OTP"
                    );
                    return;
                }

                this.$store.commit("setOverlayLoading", true);

                try {
                    const response = await this.$http.post(
                    `${this.$apiTripweWebV2}/notifications/send-otp`,
                    {
                        phone: this.form.phone,
                        scope: "UPDATE_PIN",
                    }
                    );

                    if (response.data.status == 200 || response.data.status == 403) {
                        var curTime = this.$moment();
                        var nextReq = this.$moment(response.data.data.next_request_at);
                        var diff = nextReq.diff(curTime, 'seconds');
                        if(response.data.status == 403){
                            this.$toasted.global.error(response.data.message);
                        }else{
                            this.$toasted.global.success(response.data.message);
                        }
                        this.setOtpTimer(diff);
                    }else{
                        throw response.data.message;
                    }
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.$store.commit("setOverlayLoading", false);
            },
            async updatePin() {
                this.$store.commit('setOverlayLoading', true);

                try {
                    
                    const response = await this.$http.post(
                        `${this.$apiTripweWebV2}/users/update-pin`,
                        {
                            'old_pin': this.form.currentPin,
                            'new_pin': this.form.newPin,
                            'confirm_new_pin': this.form.confirmationPin,
                            'otp': this.otp,
                        }
                    );

                    if (response.data.status != '200') {
                        throw 'Update PIN failed';
                    }

                    this.$toasted.global.success('Your PIN has been updated');
                } catch (error) {
                    this.$toasted.global.error(error);
                }

                this.resetForm();

                this.$store.commit('setOverlayLoading', false);
            },
            
            setOtpTimer(second) {
                this.otpTimer = second;
                this.startOtpTimerCountdown();
            },

            startOtpTimerCountdown() {
                if (this.otpTimer > 0) {
                    setTimeout(() => {
                        this.otpTimer -= 1;
                        this.startOtpTimerCountdown();
                    }, 1000);
                }
            },
        }
    };
</script>
